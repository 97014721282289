<template>
    <div class="shop_main">
        <div class="shopBuyingDetail supply">
            <companySide :companyName="companyName" :companyId="companyId" @goSupplyBuyingDetail="goSupplyBuyingDetail" />

            <div class="contain" v-loading="isRequestLoading">
                <div class="title">
                    <h2>{{infoData.purchase.title}}</h2>
                </div>

                <div class="content">
                    <div class="head">
                        <div class="times">
                            <span class="time">供应发布时间： {{infoData.purchase.createdAt}}</span>
                            <span class="time">预出售日期： {{infoData.purchase.receiptAt}}</span>
                            <span class="time">供应截止日期： {{infoData.purchase.stopAt}}</span>
                        </div>
                    </div>

                    <div class="subTitle">
                        <h1>供应商品</h1>
                    </div>

                    <div class="goodsInfo">
                        <div class="line">
                            <span class="label">商品名称</span>
                            <span class="label">商品规格</span>
                            <span class="label">采购量</span>
                            <span class="label">采购价格</span>
                            <span class="label"></span>
                        </div>
                        <div class="line">
                            <span class="text">{{infoData.purchase.goodName}}</span>
                            <span class="text">{{infoData.purchase.sku}}</span>
                            <span class="text">{{infoData.purchase.num}}{{infoData.purchase.unitName}}</span>
                            <span class="text"><span>¥ {{infoData.purchase.price}}</span> {{getPriceStatus(infoData.purchase.priceStatus)}}</span>
                            <span class="text">
                                <el-image
                                        v-if="infoData.purchase.imageUrl"
                                        style="width: 50px; height: 50px"
                                        fit="contain"
                                        :src="imageURL + infoData.purchase.imageUrl"
                                        :preview-src-list="[imageURL + infoData.purchase.imageUrl]">
                                </el-image>
                            </span>
                        </div>
                    </div>

                    <div class="subTitle">
                        <h1>联系方式</h1>
                    </div>

                    <div class="contactDesc">
                        <span class="text">联系人：{{infoData.purchase.contactName}}</span>
                        <span class="text">联系电话：{{infoData.purchase.contactPhone}}</span>
                    </div>

                    <div class="subTitle">
                        <h1>详细说明</h1>
                    </div>

                    <div class="buyDesc">{{infoData.purchase.content}}</div>

                    <div class="footBtn">
                        <el-button @click="$router.go(-1)">返 回</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getBuyingInfo} from "../../../api/shop/supplyBuying";
  import {imageURL} from "../../../utils/config";

  const companySide = () => import('../../../components/layout/shop/supplyBuying/companySide')
  export default {
    name: "SupplyDetail",
    data () {
      return {
        companyName: this.$route.query.companyName,
        companyId: this.$route.query.companyId,
        id: this.$route.query.id,
        infoData: {},
        isRequestLoading: false,
        imageURL: imageURL,
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getInfo(this.$route.query.id)
    },
    methods: {
      getInfo (id) {
        this.isRequestLoading = true
        getBuyingInfo(id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.infoData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      goSupplyBuyingDetail(e) {
        if (e.type === 1) {
          this.$router.push({path: '/shop/buying/detail', query: {id: e.id, companyId: this.companyId, companyName: this.companyName}})
        }
        if (e.type === 2) {
          this.getInfo (e.id)
        }
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
    },
    components: {
      companySide
    }
  }
</script>
